$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: $spacing-11;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
  }
}

.leftPart {
  @media screen and (min-width: $breakpoint_l) {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &.hideLeftOnMobile {
    display: none;

    @media screen and (min-width: $breakpoint_l) {
      display: flex;
    }
  }
}

.rightPart {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    width: 50%;
  }

  .mobileSection {
    display: flex;
    flex-direction: column;
    padding-bottom: $spacing-5;

    @media screen and (min-width: $breakpoint_l) {
      display: none;
    }
  }
}
