$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  padding: $spacing-6 0;
}

.form {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 20px;
  }
}

.list {
  display: grid;
  gap: $spacing-5;
  grid-template-columns: repeat(1, 264px);

  @media screen and (min-width: $breakpoint_m) {
    grid-template-columns: repeat(2, 264px);
  }

  @media screen and (min-width: $breakpoint_l) {
    grid-template-columns: repeat(2, 264px);
  }
}

.button {
  @extend %button-reset;

  cursor: pointer;
  background-color: $background-light;
  border-radius: $radius-2;
  padding: $spacing-4;
  border: $border-1 solid $background-light;

  img {
    max-width: 330px;
    width: 100%;
    border-radius: $radius-1;
    overflow: hidden;
  }

  &.selected {
    border: $border-1 solid $accent-default;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
