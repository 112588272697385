$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  padding: $spacing-6 0;
}

.form {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 20px;
  }
}

.list {
  display: grid;
  grid-gap: $spacing-4;
  grid-template-columns: repeat(2, minmax(100px, 164px));

  @media screen and (min-width: $breakpoint_m) {
    grid-template-columns: repeat(3, 164px);
  }

  @media screen and (min-width: $breakpoint_l) {
    grid-template-columns: repeat(4, 164px);
  }
}

.button {
  @extend %button-reset;

  cursor: pointer;
  background-color: $background-light;
  border-radius: $radius-2;
  padding: $spacing-4;
  border: $border-1 solid $background-light;

  img {
    max-width: 330px;
    width: 100%;
    border-radius: $radius-1;
    overflow: hidden;
  }

  &.selected {
    border: $border-1 solid $accent-default;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: $background-light;
  border-radius: $radius-2;
  padding: $spacing-4;
  border: $border-1 solid $background-light;

  img {
    max-width: 330px;
    width: 100%;
    border-radius: $radius-1;
    overflow: hidden;
  }

  &.selected {
    border: $border-1 solid $accent-default;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.colorPickerInput {
  opacity: 0;
  position: absolute;
  width: 164px;
  height: 164px;
  top: 0;
  left: 0;
  border-radius: 0;
}

.hiddenColorPicker {
  visibility: hidden;
}

.colorPickerButton {
  @extend %button-reset;
}
