$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  padding: $spacing-6 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  padding: $spacing-6 0;

  @media screen and (min-width: $breakpoint_xl) {
    margin-left: 60px;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  border: $border-2 dashed $border-default;
  border-radius: $radius-2;
  background-color: $background-default;
  padding: $spacing-4;
}

.uploadWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-4;
}

.label {
  @include text-subtitle2;

  margin-bottom: $spacing-2;
}

.description {
  @include text-body2Regular;

  color: $lights-low;
  margin-bottom: $spacing-1;
}

.previewImagesList {
  width: 100%;
  max-width: 348px;
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 164px));
  grid-gap: $spacing-4;

  & > div {
    height: 166px;
  }
}
