$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  padding: $spacing-6 0;
}

.form {
  padding: $spacing-6 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
