$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.slider {
  width: 100%;
  // css way as it's too hard to style inline as recommended
  [class="rc-slider-handle"],
  [class="rc-slider-handle rc-slider-handle-click-focused"] {
    position: relative;
    background: linear-gradient(
      150.64deg,
      $accent-default 17.2%,
      $accent-dim 87.57%
    );
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25),
      inset 0px 0px 2px rgba(0, 0, 0, 0.1);

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $lights-high;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "";
      width: 84px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='84' height='20' viewBox='0 0 84 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2667 10.0167L3.775 10.0167' stroke='%23C7C5C2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.76416 15.01L3.73333 10L8.76416 4.99' stroke='%23C7C5C2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M67.7333 9.98333H80.225' stroke='%23C7C5C2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M75.2358 4.99001L80.2667 10L75.2358 15.01' stroke='%23C7C5C2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 70px;
  }
}
