$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

.root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  flex-shrink: 0;
  user-select: none;

  &.withoutFill {
    fill: none;
  }
}
