$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  padding: $spacing-6 0;
}

.form {
  display: flex;
  gap: $spacing-5;
  padding: $spacing-6 0;
  width: 100%;
}

.button {
  @extend %button-reset;

  cursor: pointer;
  background-color: $background-light;
  border-radius: $radius-2;
  padding: $spacing-4;
  border: $border-1 solid $background-light;

  img {
    max-width: 330px;
    width: 100%;
    border-radius: $radius-1;
    overflow: hidden;
  }

  &.selected {
    border: $border-1 solid $accent-default;
  }
}
