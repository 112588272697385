$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

.toastWrapper {
  margin-top: $mobile_header_height;

  @media screen and (min-width: $breakpoint_m) {
    margin-top: $desktop_header_height;
  }
}

.toastWrapperMobile {
  top: 0;
  margin-top: 0;
}

.toastContainer {
  border-radius: 4px;
  padding: 10px;
}

.toastBody {
  padding: 0 10px 10px 0;
}