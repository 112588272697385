$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-1;
}

.label {
  display: block;
  color: $lights-high;

  @include text-body2Medium;
}

.hint {
  color: $lights-low;
  display: inline-flex;
  align-items: center;
  gap: $spacing-1;
}

.leftIcon {
  color: $lights-high;
  font-size: 24px;
}
