$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

.root {
  display: flex;
  position: relative;
  flex-shrink: 0;

  &.size- {
    &small {
      .removeButton {
        top: 4px;
        right: 4px;
      }
    }

    &medium {
      .removeButton {
        top: 12px;
        right: 12px;
      }
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  background: $white;
}

.removeButton {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.03))
    drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.04));
  border-radius: 50%;

  @extend %button-reset;

  > svg {
    font-size: 12px;
  }

  &:hover {
    opacity: 0.9;
  }
}
