$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-1;
}

.label {
  @include text-body2Medium;
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
  width: 100%;
  border: $border-1 solid $border-default;
  border-radius: $radius-1;
  background-color: $background-default;

  &.error {
    border-color: $error-default;
  }
}

.input {
  margin: 0;
  padding: 0;
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  font-family: inherit;
  appearance: none;
  color: $lights-high;

  @include text-body1Regular16;

  &::placeholder {
    color: $lights-low;
  }

  &.large {
    padding: $spacing-3 $spacing-3;

    &.withIcon {
      padding-left: $spacing-7;
    }
  }

  &.medium {
    padding: $spacing-2 $spacing-3;

    &.withIcon {
      padding-left: $spacing-7;
    }
  }

  &.small {
    padding: $spacing-1 $spacing-2;

    &.withIcon {
      padding-left: $spacing-7;
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: $spacing-3;

  &.small {
    left: $spacing-2;
  }
}

.passwordToggle {
  @extend %button-reset;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $spacing-3;
  color: $lights-low;

  &.small {
    left: $spacing-2;
  }
}

.hintWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-1;

  &.onlyCounter {
    justify-content: flex-end;
  }
}

.hint {
  color: $lights-low;
  display: inline-flex;
  align-items: center;
  gap: $spacing-1;
}

.noticeLabel {
  display: inline-flex;
  color: $lights-low;
  gap: $spacing-1;

  @media screen and (min-width: $breakpoint_m) {
    text-wrap: nowrap;
  }
}
