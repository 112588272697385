$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: inline-flex;
  align-self: flex-start;
  padding: $spacing-1;
  background-color: $lights-high;
  border-radius: $radius-0;
}
