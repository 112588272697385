$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.text {
  font-family: "GT America";

  &.h1 {
    @include text-h1;
  }

  &.h2 {
    @include text-h2;
  }

  &.h3 {
    @include text-h3;
  }

  &.h4 {
    @include text-h4;
  }

  &.h5 {
    @include text-h5;
  }

  &.h6 {
    @include text-h6;
  }

  &.subtitle1 {
    @include text-subtitle1;
  }

  &.subtitle2 {
    @include text-subtitle2;
  }

  &.subtitle3 {
    @include text-subtitle3;
  }

  &.body1Regular18 {
    @include text-body1Regular18;
  }

  &.body1Medium18 {
    @include text-body1Medium18;
  }

  &.body1Regular16 {
    @include text-body1Regular16;
  }

  &.body1Medium16 {
    @include text-body1Medium16;
  }

  &.body2Regular {
    @include text-body2Regular;
  }

  &.body2Medium {
    @include text-body2Medium;
  }

  &.body3Regular {
    @include text-body3Regular;
  }

  &.body3Medium {
    @include text-body3Medium;
  }

  &.body4Regular {
    @include text-body4Regular;
  }

  &.body4Medium {
    @include text-body4Medium;
  }

  &.buttonL {
    @include text-buttonL;
  }

  &.buttonM {
    @include text-buttonM;
  }

  &.buttonS {
    @include text-buttonS;
  }

  &.captionRegular {
    @include text-captionRegular;
  }

  &.overlineMedium {
    @include text-overlineMedium;
  }

  &.xLargeLabelRegular {
    @include text-xLargeLabelRegular;
  }

  &.xLargeLabelBold {
    @include text-xLargeLabelBold;
  }

  &.largeLabelRegular {
    @include text-largeLabelRegular;
  }

  &.largeLabelBold {
    @include text-largeLabelBold;
  }

  &.smallLabelRegular {
    @include text-smallLabelRegular;
  }

  &.smallLabelBold {
    @include text-smallLabelBold;
  }

  &.xSmallLabelRegular {
    @include text-xSmallLabelRegular;
  }

  &.xSmallLabelBold {
    @include text-xSmallLabelBold;
  }

  &.ellipsis {
    max-width: 100%;
    @include inline_overflow_ellipsis;
  }

  &.error {
    color: $error-default;
  }

  &.success {
    color: $success-default;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: lowercase;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  @each $group, $values in $colors {
    @each $variant, $color in $values {
      &.color-#{$group}-#{$variant} {
        color: $color;
      }
    }
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }
}
